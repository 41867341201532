import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

/**
 * NavigationGroup component
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function NavigationGroup(props) {
  const {
    items,
    header,
    isDashboard,
    isBurgerMenu,
    iconComponent,
  } = props;

  let head = (
    <React.Fragment> {/*eslint-disable-line*/}
      {iconComponent}
      <span>
        {header}
      </span>
    </React.Fragment>
  );
  if (isDashboard) {
    const item = items[0];
    head = (
      <NavLink
        exact
        className=""
        to={item.link}
        onClick={item.click}
        aria-label={item.label}
        activeClassName="isActive"
        id={isBurgerMenu ? `${item.link}-burger-menu` : item.link}
      >
        {iconComponent}
        <span>{item.label}</span>
      </NavLink>
    );
  }

  const linkItems = [];
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (!isDashboard) {
      if (item.external) {
        linkItems.push(
          <li key={item.label}>
            <a className="manz-link" href={item.link} target="_blank" rel="noopener noreferrer" title={item.tooltip || item.label} aria-label={item.label}><span>{item.label}</span></a>
          </li>,
        );
      } else {
        linkItems.push(
          <li key={item.label}>
            <NavLink
              exact
              to={item.link}
              onClick={item.click}
              aria-label={item.label}
              id={isBurgerMenu ? `${item.link}-burger-menu` : item.link}
              className={(!item.disabled || item.disabled === false) ? '' : 'manz-disabled'}
            >
              <span>{item.label}</span>
            </NavLink>
          </li>,
        );
      }
    }
  }

  return (
    <div className="navigation-group">
      <div className="navigation-group-header">
        {head}
      </div>
      <ul className={isBurgerMenu ? 'navigation-group-items-burger-menu' : 'navigation-group-items'}>
        {linkItems}
      </ul>
    </div>
  );
}

NavigationGroup.defaultProps = {
  items: null,
  header: null,
  isDashboard: false,
};

NavigationGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    external: PropTypes.bool,
    disabled: PropTypes.bool,
    click: PropTypes.func,
  })),
  header: PropTypes.string,
  isBurgerMenu: PropTypes.bool.isRequired,
  iconComponent: PropTypes.element.isRequired,
  isDashboard: PropTypes.bool,
};

export default NavigationGroup;
