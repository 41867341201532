import React from 'react';
import PropTypes from 'prop-types';
import info from '../../assets/icons/mz-apps.svg';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function VerlinkungIcon(props) {
  const { className, onClick } = props;
  return (
    <img // eslint-disable-line
      src={info}
      width={31}
      height={31}
      onClick={onClick}
      alt="verlinkung icon"
      id="manz-verlinkung"
      className={className}
      aria-label="verlinkung icon"
    />
  );
}

VerlinkungIcon.defaultProps = {
  className: null,
  onClick: null,
};

VerlinkungIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default VerlinkungIcon;
